import {
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  Input,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { dsConfig } from '@design-system/cdk/config';

@Directive({
  selector: '[dsButtonPending]',
  standalone: false,
})
export class PendingButtonDirective {
  @Input()
  set dsButtonPending(isPending: boolean) {
    const elem = this.el.nativeElement;

    if (isPending && elem) {
      elem.disabled = true;
      this.isAlreadyPending = true;

      if (!elem.className.includes('mat-menu-item')) {
        this.handleSpinner(elem);
      }
    } else if (this.isAlreadyPending) {
      if (!elem.classList.contains('mat-mdc-button-disabled'))
        elem.disabled = false;
      if (this.spinner) {
        this.renderer.removeChild(elem, this.spinner._elementRef.nativeElement);
      }
      if (this.icon) {
        this.renderer.setStyle(this.icon, 'display', 'inline-block');
        this.icon = null;
      }
      this.isAlreadyPending = false;
    }
  }

  spinner?: MatProgressSpinner;
  icon: ChildNode | null = null;
  isAlreadyPending = false;
  spacing = dsConfig.spacing;

  constructor(
    private el: ElementRef<HTMLButtonElement>,
    private renderer: Renderer2,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {}

  handleSpinner(elem: HTMLButtonElement) {
    this.createSpinner();

    if (elem.innerHTML.toString().includes('mat-icon')) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion

      this.icon = elem.getElementsByTagName('mat-icon')[0];
      this.renderer.setStyle(this.icon, 'display', 'none');
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (elem.children[0]!.childNodes.length > 1) {
        this.renderer.setStyle(
          this.spinner?._elementRef.nativeElement,
          'margin-right',
          '14px',
        );
      } else {
        this.renderer.setStyle(
          this.spinner?._elementRef.nativeElement,
          'margin-right',
          this.spacing * 0.5 + 'px',
        );
      }
      this.renderer.setStyle(
        this.spinner?._elementRef.nativeElement,
        'vertical-align',
        'middle',
      );
    } else {
      this.renderer.addClass(
        this.spinner?._elementRef.nativeElement,
        'pending-spinner',
      );
    }
    this.renderer.insertBefore(
      elem,
      this.spinner?._elementRef.nativeElement,
      elem.children[0],
    );
  }

  private createSpinner() {
    const factory =
      this.componentFactoryResolver.resolveComponentFactory(MatProgressSpinner);
    const componentRef = this.viewContainerRef.createComponent(factory);
    this.spinner = componentRef.instance;
    this.spinner.mode = 'indeterminate';
    this.spinner.diameter = this.spacing;
    this.spinner.color = 'accent';
  }
}
