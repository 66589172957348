import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { cloneDeep } from 'lodash-es';
import {
  Position,
  PositionStatus,
  RefundType,
} from '../../../shared/generated';

export interface EditablePosition extends Position {
  edit_mode?: PositionStatus;
  edit_reason?: string;
}

@Component({
  selector: 'cos-claim-positions',
  templateUrl: './claim-positions.component.html',
  styleUrls: ['./claim-positions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ),
    ]),
  ],
  standalone: false,
})
export class ClaimPositionsComponent {
  objectKeys = Object.keys;
  positionStatus = PositionStatus;
  refundType = RefundType;
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    if (ms && !this.dataSource.sort) {
      this.dataSource.sort = ms;
    }
  }

  initialPositions: EditablePosition[];
  availableActions = [
    PositionStatus.Accepted,
    PositionStatus.Declined,
    PositionStatus.FurtherInformationNecessary,
  ];

  _positions: EditablePosition[];
  @Input() set claimPositions(pos: Position[]) {
    this._positions = pos;
    this.initialPositions = cloneDeep(pos); // This is need for undo-changes functionality.
    this.dataSource.data = this._positions;
  }
  expanded?: number;

  displayedColumns = [
    'material_number',
    'serial_number',
    'quantity',
    'description',
    'state',
    'requested',
    'accepted',
    'reason',
  ];
  dataSource = new MatTableDataSource();

  changePositionStatus(
    event: MatSelectChange,
    position: EditablePosition,
  ): void {
    this.expanded = position.position_id;
    if (event.value) {
      position.edit_mode = event.value;
    }
    switch (event.value) {
      case PositionStatus.Accepted: {
        position.accepted_value = position.requested_value;
        position.refund_type = RefundType.CreditNote;
        break;
      }
      case PositionStatus.Declined:
      case PositionStatus.FurtherInformationNecessary: {
        position.refund_type = undefined;
        position.accepted_value = 0;
        break;
      }

      default:
        return;
    }
  }

  /**
   * Reverts the position to it's initial state from BE
   * @param material_number
   */
  undoChanges(position_id: number): void {
    this.expanded = undefined;

    const targetIndex = this._positions.indexOf(
      this._positions.find((p) => p.position_id === position_id) ||
        ({} as EditablePosition),
    );

    this._positions[targetIndex] = cloneDeep(
      this.initialPositions.find((p) => p.position_id === position_id) ||
        ({} as EditablePosition),
    );

    this.dataSource.data = this._positions;
  }

  /**
   * If the value is more than requested - set the requested value
   * If the value is zero - set 0.01 as value
   * Otherwise the value is in range from 0 to requested_value and is valid to be set as the accepted value
   *
   * @param target - acceptedValue field
   * @param position - Position
   */
  updateAcceptedValue(target, position: EditablePosition): void {
    const val_ = parseFloat(target.value);
    if (val_ >= position.requested_value) {
      position.edit_mode = PositionStatus.Accepted;
      position.accepted_value = position.requested_value;
    } else {
      position.edit_mode = PositionStatus.AcceptedPartially;
      position.accepted_value =
        val_ <= 0.01 ? 0.01 : Math.floor(val_ * 100) / 100;
    }
  }
}
