import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ds-placeholder',
  templateUrl: './placeholder.component.html',
  styleUrls: ['./placeholder.component.scss'],
  standalone: false,
})
export class PlaceholderComponent implements OnChanges {
  @Input() type: string;
  @Input() customHeadline: string;
  @Input() isLoadingState: boolean;
  @Input() iconSize: string;
  @Input() iconStyle?: 'outlined' | 'filled';

  icon: string;
  class: string;
  headline: string;
  color: string;

  ngOnChanges({ type, iconStyle }: SimpleChanges): void {
    if (!type) {
      return;
    }

    switch (type.currentValue) {
      case 'no_data':
        this.icon = 'assets/svg/icons/no-search-result.svg';
        this.class = 'no_data';
        this.headline = 'general.no_data_available';
        break;
      case 'attachments':
        this.icon = 'attach_file';
        this.class = 'attachments';
        this.headline = 'attachments.no_attachments';
        break;
      case 'error':
        this.icon =
          iconStyle?.currentValue === 'filled' ? 'error' : 'error_outline';
        this.headline = 'general.error_code.error';
        this.class = 'error-text';
        break;
      case 'success':
        this.icon = 'check_circle';
        this.class = 'success-text';
        break;
      case 'info':
        this.icon = 'info';
        this.class = 'info-text';
        break;
      case 'warning':
        this.icon = 'warning';
        this.class = 'warning-text';
        break;
      case 'no_telematic':
        this.icon = 'wifi_off';
        this.headline = 'general.no_telematic_view';
        this.class = 'no_telematic';
        break;
      case 'add':
        this.icon = 'add_circle';
        this.class = 'add';
        break;
      default:
        this.icon = type.currentValue;
        this.class = 'custom';
        break;
    }
  }
}
