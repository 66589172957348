import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cos-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})
export class AppComponent implements OnDestroy {
  isHelpPage = false;
  private destroy$ = new Subject<void>();

  constructor(router: Router) {
    // show sidebar for the help pages
    router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event) => event as NavigationEnd),
        takeUntil(this.destroy$),
      )
      .subscribe((event) => (this.isHelpPage = Boolean(event.url === '/help')));
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
