import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  AttachedFile,
  FileOptions,
  FileUploadState,
} from '@design-system/cdk/file-drop';
import { Subject } from 'rxjs';

@Component({
  selector: 'ds-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss'],
  standalone: false,
})
export class AttachmentsComponent {
  @Input() set attachments(val: AttachedFile[]) {
    if (val) {
      if (!this.dataSource) this.dataSource = new MatTableDataSource();
      this.dataSource.data = val;
    }
  }

  @Input() documentOptions: FileOptions;
  @Input() customNoDataHeadline: string;
  @Input() uploadButtonText: string;
  @Input() showPlaceholder = true;
  @Input() set showFileSize(val: boolean) {
    this.setColumn(val, 'file_size');
  }
  @Input() set readOnlyMode(val: boolean) {
    this._readOnlyMode = val;
    this.setColumn(!val, 'actions');
  }
  @Input() set hideThumbnail(val: boolean) {
    this.setColumn(!val, 'thumbnail');
  }

  @Input() canUpload = true;
  @Input() allowMultiple = true;

  @Output() fileSelect = new EventEmitter<File[]>();
  @ViewChild('errors') dialogRef: TemplateRef<any>;
  @ContentChild('actions', { static: false })
  actionsTemplateRef: TemplateRef<any>;
  sort: MatSort;
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    if (ms) {
      this.sort = ms;
      this.dataSource.sort = this.sort;
    }
  }
  displayedColumns: string[] = ['thumbnail', 'file_name'];
  isFileOver$ = new Subject<boolean>();
  dataSource: MatTableDataSource<AttachedFile>;
  errorDialogRef: MatDialogRef<any>;
  _readOnlyMode: boolean;

  constructor(private dialog: MatDialog) {}

  onFileSelect(event: FileUploadState[]): void {
    const errors: {
      fileNameTooBigErrorFiles: string[];
      fileSizeTooBigErrorFiles: string[];
      fileTypeNotAllowedErrorFiles: string[];
    } = {
      fileNameTooBigErrorFiles: [],
      fileSizeTooBigErrorFiles: [],
      fileTypeNotAllowedErrorFiles: [],
    };
    event.forEach((fileState) => {
      if (fileState.fileNameTooBigError) {
        errors.fileNameTooBigErrorFiles.push(fileState.file.name);
      }

      if (fileState.fileSizeTooBigError) {
        errors.fileSizeTooBigErrorFiles.push(fileState.file.name);
      }

      if (fileState.fileTypeNotAllowedError) {
        errors.fileTypeNotAllowedErrorFiles.push(fileState.file.name);
      }
    });

    if (
      errors.fileNameTooBigErrorFiles.length ||
      errors.fileSizeTooBigErrorFiles.length ||
      errors.fileTypeNotAllowedErrorFiles.length
    ) {
      this.errorDialogRef = this.dialog.open(this.dialogRef, {
        data: {
          errors: errors,
        },
        width: '800px',
      });
    } else {
      this.fileSelect.emit(event.map((fileState) => fileState.file));
    }
  }

  closeErrorDialog() {
    this.errorDialogRef?.close();
  }

  getIconFromFileName(fileName: string): string {
    switch (this.getExtensionFromFileName(fileName)) {
      case 'doc':
      case 'docx':
        return 'word-icon';
      case 'xls':
      case 'xlsx':
        return 'excel-icon';
      case 'ppt':
      case 'pptx':
        return 'powerpoint-icon';
      case 'pdf':
        return 'pdf-icon';
      case 'zip':
      case 'rar':
      case '7zip':
        return 'compressed-icon';
      case 'wmv':
      case 'mp4':
      case 'mpg':
      case 'mpeg':
      case 'avi':
      case 'mov':
        return 'video-icon';
      default:
        return 'other-icon';
    }
  }

  getExtensionFromFileName(fileName: string) {
    return fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase();
  }

  setColumn(addColumn: boolean, columnName: string) {
    if (addColumn) {
      this.displayedColumns.push(columnName);
    } else {
      const index = this.displayedColumns.indexOf(columnName);

      if (index > -1) {
        this.displayedColumns.splice(index, 1);
      }
    }
  }
}
