import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Convert number of bytes into largest possible unit.
 * @usage:
 *   value | pdFileSize
 *
 * @usage:
 *   bytes | fileSize:precision
 * @example
 * {{2048 | fileSize}} <!-- 2 KB -->
 *
 * @description
 * - digitsInfo See {@link DecimalPipe} for detailed description. This argument is ignored for bytes unit.
 * - locale See {@link DecimalPipe} for detailed description.
 *
 */
@Pipe({
  name: 'pdFileSize',
  standalone: false,
})
export class FileSizePipe implements PipeTransform {
  // credits to https://gist.github.com/JonCatmull/ecdf9441aaa37336d9ae2c7f9cb7289a#file-file-size-pipe-ts

  private static units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
  constructor(private currencyPipe: DecimalPipe) {}

  transform(value: any, locale?: string): string {
    if (value == null || String(value) === '') {
      return '';
    }

    let unit = 0;
    if (!isNaN(parseFloat(String(value))) && isFinite(value)) {
      while (value >= 1024) {
        value /= 1024;
        unit++;
      }
    }

    return `${this.currencyPipe.transform(value, '1.0-0', locale)} ${
      FileSizePipe.units[unit]
    }`;
  }
}
