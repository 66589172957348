import { Component, Inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarModule,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import {
  DsTextIndicatorModule,
  DsTextIndicatorType,
} from '@design-system/components/text-indicator';
import { take } from 'rxjs/operators';
import { DsSnackbarData } from './snackbar.models';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DsSpacingModule } from '@design-system/cdk/spacing';

@Component({
  selector: 'ds-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    DsTextIndicatorModule,
    FlexLayoutModule,
    DsSpacingModule,
  ],
})
export class DsSnackbarComponent {
  type: DsTextIndicatorType;
  message: string;
  action?: string;
  snackbarRef: MatSnackBarRef<DsSnackbarComponent>;
  href?: string;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: DsSnackbarData) {
    this.message = data.message;
    this.type = data.type || 'info';
    this.action = data.action || '';
    this.href = data.href;
    data.ref.pipe(take(1)).subscribe((ref) => (this.snackbarRef = ref));
  }
}
