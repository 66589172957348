import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

export class NgLetContext {
  $implicit: any = null;
  pdLet: any = null;
}

// workaround for missing *ngLet in templates see https://github.com/angular/angular/issues/15280
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[pdLet]',
  standalone: false,
})
export class PdLetDirective implements OnInit {
  private _context = new NgLetContext();

  @Input()
  set pdLet(value: any) {
    this._context.$implicit = this._context.pdLet = value;
  }

  constructor(
    private _vcr: ViewContainerRef,
    private _templateRef: TemplateRef<NgLetContext>,
  ) {}

  ngOnInit() {
    this._vcr.createEmbeddedView(this._templateRef, this._context);
  }
}
