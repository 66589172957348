import { Component, Input } from '@angular/core';
import { DsTextIndicatorType } from './text-indicator.type';

@Component({
  selector: 'ds-text-indicator',
  templateUrl: './text-indicator.component.html',
  styleUrls: ['./text-indicator.component.scss'],
  standalone: false,
})
export class DsTextIndicatorComponent {
  @Input() type: DsTextIndicatorType = 'info';
  @Input() iconSize = '20px';
  @Input() noColor = false;
}
